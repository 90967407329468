import React, { useState } from 'react';
import cn from 'classnames';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { CheckoutFields, useCheckoutFormik } from '../../../lib/formik/useCheckoutFormik';
import { useRouter } from '../../../lib/hooks/useRouter';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';
import { useCartContext } from '../../../lib/context/CartContext/CartContext';
import { formInputs, messages } from '../../../lib/translation/strings';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { BillingFields } from '../BillingFields/BillingFields';
import { PaymentMethod } from '../PaymentMethod/PaymentMethod';
import { ShippingMethod } from '../ShippingMethod/ShippingMethod';
import { ShippingFields } from '../ShippingFields/ShippingFields';
import { CheckoutHeader } from '../CheckoutHeader/CheckoutHeader';
import { CheckoutFooter } from '../CheckoutFooter/CheckoutFooter';
import { ProfileAddressFields } from '../../ProfilePages/ProfileAddress/ProfileAddress';
import { formatGraphqlErrors } from '../../../lib/helpers/formatGraphqlErrors';
import {
  CHECKOUT_ORDER,
  CheckoutResponse,
  CheckoutVariables,
  CheckoutInputData,
} from '../../../queries/mutations/checkoutMutation';
import { ApplyCoupon } from '../ApplyCoupon/ApplyCoupon';
import { LoadingPlaceholder } from '../../shared/LoadingPlaceholder/LoadingPlaceholder';
import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';

import './CheckoutForm.scss';

interface Props {
  customer: ProfileAddressFields;
}

export const CheckoutForm = (props: Props) => {
  const customer = props.customer;
  const authCtx = useAuthContext();
  const router = useRouter();
  const cartCtx = useCartContext();
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const [error, setError] = useState('');

  const [checkoutMutation] = useMutation<CheckoutResponse, CheckoutVariables>(CHECKOUT_ORDER);

  const formik = useCheckoutFormik({
    initialValues: {
      billing: {
        firstName: customer?.billing.firstName || '',
        lastName: customer?.billing.lastName || '',
        address1: customer?.billing.address1 || '',
        city: customer?.billing.city || '',
        postcode: customer?.billing.postcode || '',
        state: customer?.billing.state || '',
        country: customer?.billing.country || '',
        phone: customer?.billing.phone || '',
      },
      shipping: {
        firstName: customer?.shipping.firstName || '',
        lastName: customer?.shipping.lastName || '',
        address1: customer?.shipping.address1 || '',
        city: customer?.shipping.city || '',
        postcode: customer?.shipping.postcode || '',
        state: customer?.shipping.state || '',
        country: customer?.shipping.country || '',
      },

      email: authCtx.user?.email || '',
      // createAccount: !authCtx.user && false,
      // password: "",
      // passwordConfirm: "",

      billingSameAsShipping: true,
      comment: '',
      termsAndConditions: false,
      paymentMethod: '',
    },
    onSubmit: async (values) => {
      setError('');
      try {
        const res = await checkoutMutation({
          variables: {
            inputData: prepareCheckoutInputData(values),
          },
        });

        const checkout = res?.data?.checkout;
        if (!checkout) {
          return;
        }

        cartCtx.emptyCart();

        if (
          (formik.values.paymentMethod === 'qpc' ||
            formik.values.paymentMethod === 'ppcp-gateway') &&
          checkout?.redirect
        ) {
          window.location.href = checkout.redirect;
        } else {
          router.push(`/checkout/${checkout.order?.databaseId}`);
        }
      } catch (error) {
        window.scrollTo(0, 0);
        setError(formatGraphqlErrors((error as Error).message));
      }
    },
  });

  const prepareCheckoutInputData = (values: CheckoutFields) => {
    const inputData: CheckoutInputData = {
      clientMutationId: 'checkout-react-app',
      billing: {
        ...values.billing,
        email: values.email,
      },
      shipToDifferentAddress: !values.billingSameAsShipping,
      paymentMethod: values.paymentMethod,
      shippingMethod: cartCtx.chosenShippingMethod,
      customerNote: values.comment,
      metaData: cartCtx.items?.flatMap((item) => {
        return item.extraData.map((data) => ({
          id: `${item.variation.node.databaseId}-${data.id}`,
          key: `${item.variation.node.databaseId}-${data.key}`,
          value: `${data.value || 'NONE'}`,
        }));
      }),
    };
    // if (values.createAccount && values.password) {
    //   inputData["account"] = {
    //     username: values.email,
    //     password: values.password
    //   };
    // }
    if (!values.billingSameAsShipping) {
      inputData['shipping'] = {
        ...values.shipping,
        firstName: values.shipping.firstName || '',
        lastName: values.shipping.lastName || '',
        address1: values.shipping.address1 || '',
        city: values.shipping.city || '',
        postcode: values.shipping.postcode || '',
        state: values.shipping.state || '',
        country: values.shipping.country || '',
      };
    }
    return inputData;
  };

  return (
    <div className='CheckoutForm'>
      {formik.isSubmitting && <LoadingPlaceholder text={t(messages.orderIsSubmitting)} />}
      <CheckoutHeader formik={formik} error={error} />

      <Row>
        <Col xs='12' md='6'>
          <BillingFields formik={formik} isAuthenticated={authCtx.isAuthenticated} />
          <Row className='mt-4'>
            <Col xs='12' md='8' lg='6'>
              {!formik.values.billingSameAsShipping && (
                <div className='Checkout__address-actions'>
                  <h4 className='Checkout__address-actions__title'>
                    {t(messages.shippingInformation)}
                  </h4>
                </div>
              )}
            </Col>
            <Col xs='12' md='8' lg='6'>
              <div className={cn('Checkout__address-actions', windowWidth > 992 && 'float-right')}>
                <button
                  className={`Checkout__address-actions__delivery ${
                    !formik.values.billingSameAsShipping ? ' is--active' : ''
                  }`}
                  onClick={() => {
                    formik.setFieldValue(
                      'billingSameAsShipping',
                      !formik.values.billingSameAsShipping,
                    );
                    formik.setFieldTouched('billingSameAsShipping');
                  }}
                >
                  {t(messages.toAnotherAddress)}
                </button>
              </div>
            </Col>
          </Row>
          {!formik.values.billingSameAsShipping && <ShippingFields formik={formik} />}
        </Col>
        <Col xs='12' md='6'>
          <OrderDetails />
          <ShippingMethod formik={formik} />
          <PaymentMethod formik={formik} />
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='comment'>{t(formInputs.specialRequest)}</Label>
            <Input
              id='comment'
              name='comment'
              type='textarea'
              className='form-control'
              value={formik.values.comment}
              onChange={formik.handleChange}
              placeholder={t(formInputs.commentPlaceholder)}
              invalid={!!formik.errors.comment && formik.touched.comment}
            />
            <FormFeedback>{formik.errors.comment}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <ApplyCoupon />
          <CheckoutFooter formik={formik} />
        </Col>
      </Row>
    </div>
  );
};
